import React, { Fragment } from 'react'
import { Spin } from 'antd'
import { multiSelectStyles } from './variable'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import Loader from '../../../../../../components/Loader'
import { orderBy } from 'lodash'

export default function RefferringClient({
  isEnable,
  isLoadingRefferingClient,
  ...props
}) {

  let options = props.refferringClientOption
  let values = props.selectedRefferringClient

  // Sort clients by name
  options = orderBy(options, [c => c.label.toLowerCase()])

  // Sort clients by selected status
  options = orderBy(options, c => values.map(v => v.value).includes(c.value) ? 0 : 1)

  // If using search, filter client options by name
  if (props.searchTerm.length > 0) {
    options = options.filter((c) => {
      return c.label.toLowerCase().includes(props.searchTerm.toLowerCase())
    })
  }
    
  return (
    <Fragment>
      {isEnable && (
        <div className="app__installed" > 
          <div className="form-group material-textfield">
            <Spin
              size="small"
              spinning={isLoadingRefferingClient}
              indicator={<Loader />}
            >
              <div className="multiselect__checkboxes__field">
                <ReactMultiSelectCheckboxes
                  styles={multiSelectStyles}
                  width="100%"
                  name="refferringClient"
                  placeholderButtonLabel="Select"
                  value={values}
                  options={options}
                  inputValue={props.searchTerm}
                  onInputChange={props.filterRefferClientsOption}
                  onChange={props.handleRefferingClient}
                />
                <label className="multiselect__checkboxes__field__label">
                  Referring Client
                </label>
              </div>
            </Spin>
          </div>
          <button
            className="btn btn__btn btn-dark btn__send"
            type="button"
            onClick={props.sendAndCreateReferringClient}
          >
            Save Referring Clients
          </button>        
        </div>
      )}
    </Fragment>
  )
}
