import React, { Fragment, useEffect, useState } from 'react'
import { map, compact } from 'lodash'
import { Spin } from 'antd'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import { multiSelectStyles } from './variable'
import Loader from '../../../../../../components/Loader'

export default function BizDevCredit({
  isEnable,
  isLoadingBizCredit,
  selectedBizDev,
  bizDevOption,
  handleBizDev,
  sendAndCreateBizDev,
}) {
  return (
    <Fragment>
      {isEnable && (
        <div className="app__installed">
          <div className="form-group material-textfield">
            <Spin
              size="small"
              spinning={isLoadingBizCredit}
              indicator={<Loader />}
            >
              <div className="multiselect__checkboxes__field">
                <ReactMultiSelectCheckboxes
                  styles={multiSelectStyles}
                  width="100%"
                  name="bizDevCredit"
                  placeholderButtonLabel="Select"
                  value={selectedBizDev}
                  options={bizDevOption}
                  onChange={handleBizDev}
                />
                <label className="multiselect__checkboxes__field__label">
                  Biz Dev Credit
                </label>
              </div>
            </Spin>
          </div>
          <button
            className="btn btn__btn btn-dark btn__send"
            type="button"
            onClick={sendAndCreateBizDev}
            >
              Save Biz Dev Credits
            </button>
        </div>
      )}
    </Fragment>
  )
}
