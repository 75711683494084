/* eslint-disable camelcase */
/* eslint-disable no-magic-numbers */
import React, { PureComponent } from "react"
import { Route, Switch, Link } from "react-router-dom"
import  { DatePicker } from "antd"
import { get, filter, map, find, size, isEmpty } from 'lodash'
import Cookies from "universal-cookie";
import moment from "moment";
import TopNavigation from "../../../../components/TopNavigation/container"
import AdministrationSidebarNavigation from "../../../../components/AdministrationSidebarNavigation/component"
import { ClientsColorIcon, CloseIcon } from "../../../../components/icons"
import AllClients from './partials/AllClient/component'
import NewClients from './partials/NewClients/component'
import "./styles.scss"
import SelectPageResult from "./partials/layout/SelectPageResult";
import FilterUser from "./partials/layout/FilterUser";
import { SUCCESS } from '../../../../constants/phase';
import { ClientContext } from "./context/ClientContext";
import qs from 'qs';
import { Popover, Button, notification, Input } from 'antd';
import {getClientsApiEndpoint} from "../../../../utils/helper"

const { TextArea } = Input

const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST

const cookies = new Cookies();
export default class ClientsComponent extends PureComponent {
  constructor(props) {
    super(props)
      
    this.state = {
      timingsData: [
        { id: 1, name: "ASAP" }, { id: 2, name: "Uncertain" }, { id: 3, name: "Spring 2021" },
        { id: 4, name: "Fall 2021" }, { id: 5, name: "Spring 2022" }, { id: 6, name: "Fall 2022" },
        { id: 7, name: "Spring 2023" }, { id: 8, name: "Fall 2023" }, { id: 9, name: "Spring 2024" },
        { id: 10, name: "Fall 2024" }, { id: 11, name: "Spring 2025" }, { id: 12, name: "Fall 2025" },
        { id: 13, name: "Spring 2026" }, { id: 14, name: "Fall 2026" }
      ],
      isLoading: true,
      clientType: "all_clients",
      visible: false,
      resultEntry: 20,
      activeClientCount: 0,
      closedClientCount: 0,
      noFollowUp: false,
      noStatus: false,
      noTownTour: false,
      hideInactive: false,
      overdueFollowUps: false,
      clientStatus: [],
      clientSuburb: [],
      clientStrategist: [],
      clientTiming: [],
      clientAgent: [],
      clientBizDevCredit: [],
      clientCurrentLocation: false,
      status: false,
      clientLimit: 20,
      strategists: false,
      timings: false,
      statusDropdown: false,
      strategistsDropDown: false,
      timingsDropDown: false,
      filterCount: 0,
      activePage: 1,
      filterSearch: [],
      searchStatus: "",
      sortByKey: "",
      sortByType: 0,
      startDate: "",
      endDate: "",
      selectedClientStatus: [],
      selectedClientStrategists: [],
      selectedClientTimings: [],
      selectedClientSuburbs: [],
      selectedClientAgents: [],
      selectedClientTowns: [],
      selectedClientBizDevCredits: [],
      showBulkActionsFollowUpDate: false,
      showBulkActionsInternalNote: false,
      bulkActionsInternalNote: '',
      showBulkActions: false,

      // For Handling ClientContext
      isClientDataFetching: false
    }
    this.filterUserRef = React.createRef();
    this.allClientRef = React.createRef();
    this.newClientRef = React.createRef();
  }

  

  componentDidMount() {
    
    this.props.getFilterParams();
    const user = cookies.get("user");
    document.body.scrollTop = 0
    if (user) {
      const localclientdata = JSON.parse(localStorage.getItem('clientFilterData'))
      if (!get(this.props, 'location.state.filter', false)) {
        if (localclientdata && localclientdata !== null && localclientdata !== '') {
          this.setState(
            {
              isLoading: true,
              selectedClientStatus: localclientdata.clientStatus,
              selectedClientStrategists: localclientdata.clientStrategist,
              selectedClientTimings: localclientdata.clientTiming,
              selectedClientSuburbs: localclientdata.clientSuburb,
              selectedClientAgents: localclientdata.clientAgent,
              selectedClientTowns: localclientdata.clientTown,
              selectedClientBizDevCredits: localclientdata.clientBizDevCredit,
              clientType: 'all_clients',
              clientAgent: localclientdata.clientAgent,
              clientStatus: localclientdata.clientStatus,
              status: isEmpty(localclientdata.clientStatus) ? false : true,
              statusDropdown: isEmpty(localclientdata.clientStatus) ? false : true,
              clientSuburb: localclientdata.clientSuburb,
              clientStrategist: localclientdata.clientStrategist,
              clientTown: localclientdata.clientTown,
              clientBizDevCredit: localclientdata.clientBizDevCredit,
              clientCurrentLocation: localclientdata.clientCurrentLocation,
              clientTiming: localclientdata.clientTiming,
              hideInactive: localclientdata.hideInactive,
              pageLimit: localclientdata.pageLimit,
              resultEntry: localclientdata.pageLimit,
              activePage: localclientdata.activePage,
              startDate: localclientdata.startDate ? localclientdata.startDate : '',
              endDate: localclientdata.endDate ? localclientdata.endDate : '',
              filterCount: localclientdata && localclientdata.filterCount ? localclientdata.filterCount : 0,
            })
        }
      }
    }
  }
  static getDerivedStateFromProps(props, state) {
    
    let states = { ...state }
    if (props.filterClientsPhase === SUCCESS) {
      props.clearPhase()
      states = { ...states, isLoading: false }
    }
    if (props.location.pathname !== '') {
      let clientType = 'all_clients'
      if (['/strategist/clients/new-clients', '/strategist/clients'].includes(props.location.pathname)) {
        clientType = 'my_clients'
      }
      states = { ...states, isLoading: false, clientType }
    }
    return states
  }

  componentDidUpdate(prevProps, prevState) {
    
    if(this.props.clientsData && this.props.clientsData.users && prevProps.clientsData && prevProps.clientsData.users && this.props.clientsData.users.length !== prevProps.clientsData.users.length){
      this.setState({isClientDataFetching: false})
    // } else if(this.props.clientsData && this.props.clientsData.users && prevProps.clientsData && prevProps.clientsData.users && this.props.clientsData.users.length === prevProps.clientsData.users.length && this.props.filterClientsPhase === SUCCESS) {
    } else if(this.props.clientsData && this.props.clientsData.users && prevProps.clientsData && prevProps.clientsData.users && this.props.clientsData.users.length === prevProps.clientsData.users.length) {
      this.setState({isClientDataFetching: false})
    }
  }
  
  handleRouteChange = (path) => this.props.history.push(`/strategist/clients/${path}`)

  handleResultChange = (resultEntry) => this.setState({ resultEntry }, () => {
    this.fetchFilterResult()
  })

  //changeProps for mobile function
  changePropsForMobile(e) {
    
    let path = e.target.value
    this.setState({ clientType: path })
    if (path === 'my_clients') {
      this.props.history.push(`/strategist/clients/new-clients`)
    } else if (path === 'all_clients') {
      this.props.history.push(`/strategist/clients/all-clients`)
    }
  }

  fetchFilterResult = (params) => {
    
    const { clientType, clientAgent, filterCount, activePage, clientStatus, clientStrategist, clientTiming, clientSuburb, clientTown, clientBizDevCredit, clientCurrentLocation, sortByType, sortByKey, hideInactive, resultEntry } = this.state
    const localclientdata = JSON.parse(localStorage.getItem('clientFilterData'))
    const data = {
      clientType,
      clientStatus,
      clientSuburb,
      clientAgent,
      clientStrategist,
      clientTiming,
      clientTown,
      clientBizDevCredit,
      hideInactive,
      pageLimit: resultEntry,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      ...(localclientdata.sortBy && {sortBy: {key: localclientdata.sortBy.key, type: localclientdata.sortBy.type}})
    }
    // Whenever pageLimit is changed, we need to go back to page 1 to avoid any pagination issues
    if (data.pageLimit != localclientdata.pageLimit) data.activePage = 1

    if (clientCurrentLocation && clientCurrentLocation.length > 0) data.clientCurrentLocation = clientCurrentLocation

		this.setState({isLoading: true,isClientDataFetching: params === 'hasParams' ? false : true,}, () => this.props.getClientList(data))
    this.forceUpdate()
    localStorage.setItem('clientFilterData', JSON.stringify({ ...data, filterCount}))
  }

  // All Clients currently within the filters aren't held in the state.  
  // Retrieve all Clients for the current filters

  fetchBulkActionUsers = async () => {

    const currentUrl = get(this.props, "location.pathname", "")
    let clientType = 'all_clients'
    if(['/strategist/clients/new-clients', '/strategist/clients'].includes(currentUrl)) {
      clientType = 'my_clients'
    }

    const { 
      startDate, 
      endDate, 
      hideInactive, 
      selectedClientStrategists, 
      selectedClientAgents, 
      selectedClientStatus, 
      selectedClientTimings, 
      selectedClientSuburbs, 
      selectedClientTowns,
      selectedClientBizDevCredits,
      clientCurrentLocation
    } = this.state

    const params = {
      clientType,
      clientStatus: selectedClientStatus,
      clientSuburb: selectedClientSuburbs,
      clientStrategist: selectedClientStrategists,
      clientTiming: selectedClientTimings,
      clientAgent: selectedClientAgents,
      clientTown: selectedClientTowns,
      clientBizDevCredit: selectedClientBizDevCredits,
      clientCurrentLocation,
      pageLimit: -1, // retrieve all records
      hideInactive,
      startDate,
      endDate,
    }

    const queryString = qs.stringify(params) 

    return await fetch(`${SUBURBANJUNGLE_API_HOST}/clients?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': cookies.get('Authorization')
      }
    })
      .then((res) => res.json())
      .catch((error) => {
        throw error
      })    
  }

  getClientFilterParams = () => {

    const currentUrl = get(this.props, "location.pathname", "")
    var pageLimit = 7000,
    clientType = 'all_clients'
    if(currentUrl === '/strategist/clients/new-clients' || currentUrl === '/strategist/clients'){
      pageLimit = get(this.props.clientsData, `user_counts.my_clients`, 7000);
      clientType = 'my_clients'
    }
    else 
      pageLimit = get(this.props.clientsData, `user_counts.all_clients`, 7000)

    const {
      sortByKey,
      sortByType,
      startDate,
      endDate,
      hideInactive,
      selectedClientStrategists,
      selectedClientAgents,
      selectedClientStatus,
      selectedClientTimings,
      selectedClientSuburbs,
      selectedClientTowns,
      selectedClientBizDevCredits,
    } = this.state

    return {
      clientType,
      clientStatus: selectedClientStatus,
      clientSuburb: selectedClientSuburbs,
      clientStrategist: selectedClientStrategists,
      clientTiming: selectedClientTimings,
      clientAgent: selectedClientAgents,
      clientTown: selectedClientTowns,
      clientBizDevCredit: selectedClientBizDevCredits,
      hideInactive,
      pageLimit,
      activePage: 1,
      startDate,
      endDate,
      sortBy: { key: sortByKey, type: sortByType }
    }
  }

  fetchExportDataUrl = async() => {

      const params = this.getClientFilterParams()
      params.exportData = true

      const queryString = qs.stringify(params)
      const endpointUrl = `${getClientsApiEndpoint()}?${queryString}`

      await fetch(endpointUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': cookies.get('Authorization')
        }
      })
        .then((res) => res.json())
        .then((res) => {

          const downloadDataHeaders = ["Client Name", "Budget", "Status", "Created", "Strategist", "Follow-Up Date"]
          const allUserRowData = []

          for (const userRow of res.data.users) {
            const rowData = [
              userRow.client_full_name,
              userRow.price_range,
              userRow.status,
              userRow.created,
            ]
            if (userRow.strategists) {
              if (userRow.strategists.length === 1) {
                rowData.push(userRow.strategists[0].name)
                rowData.push(userRow.follow_up_date[0].follow_up_date)
              } else {
                const strategistNames = userRow.strategists.map((s) => { 
                  return s.name
                }).join(', ')
                rowData.push(`"${strategistNames}"`)
                rowData.push("Multiple Follow-Up Dates")                
              }
            }
            allUserRowData.push(rowData)
          }

          const csvRows = [downloadDataHeaders.join(',')]
          for (const r of allUserRowData) {
            csvRows.push(r.join(','))
          }
          const csvText = csvRows.join("\n")

          const downloadLink = window.document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob([csvText], {type: 'text/csv'}));
          downloadLink.download = `${moment().format('YYYY-MM-DD--HHmm')}-clients-export.csv`
          document.body.appendChild(downloadLink)
          downloadLink.click()
          document.body.removeChild(downloadLink)
          
        })
        .catch((error) => {
          throw error
        })    
      return true
  }

  fetchAlldata = async () => {

    const params = this.getClientFilterParams()
    const allData = await this.props.getClientListApi(params)
    return allData
  }

  toggleBulkActions = async() => {
    const { showBulkActions } = this.state
    this.setState({
      showBulkActions: !showBulkActions,
      showBulkActionsFollowUpDate: false,
      showBulkActionsInternalNote: false
    })
  }

  toggleBulkActionFollowUpDate = async () => {  
    this.setState({showBulkActionsFollowUpDate: !this.state.showBulkActionsFollowUpDate})
  }

  toggleBulkActionInternalNote = async () => {
    this.setState({showBulkActionsInternalNote: !this.state.showBulkActionsInternalNote})
  }

  bulkActionCopyEmails = async () => {
 
      this.fetchBulkActionUsers().then((payload) => {
        if (!payload.data.users.length) {
          notification.warning({
            message: `No clients found.  No email addresses copied.`
          })          
        } else {
          const emails = payload.data.users.map(m => m.client_email)
          navigator.clipboard.writeText(emails.join(',')).then(() => {
            notification.success({
              message: `${emails.length} email addresses copied to the clipboard`
            })
          })
        }   
        this.toggleBulkActions()
        return   
      })

  }

  bulkActionSetFollowUpDate = async (selectedDate) => {

    this.fetchBulkActionUsers().then((payload) => {
      
      if (!payload.data.users.length) {
        notification.warning({
          message: `No clients found.  No clients updated.`
        })
        this.toggleBulkActions()
        return
      } else {
        const clientIds = payload.data.users.map(m => m.client_id)
        notification.success({
          message: `Updating follow up dates for ${clientIds.length} clients ...`
        })        
        fetch(`${SUBURBANJUNGLE_API_HOST}/clients/bulk/follow-up-date`, {
          method: 'POST',
          body: JSON.stringify({
            clientIds,
            followUpDate: selectedDate.format('YYYY-MM-DD')
          }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': cookies.get('Authorization')
          }
        })
        .then((res) => res.json())
        .then((res) => {
          this.toggleBulkActionFollowUpDate()
          if (!res.status) {
            notification.warning({
              message: `An error occurred.  Please contact support.`
            })
          } else {
            notification.success({
              message: `Follow up dates for ${clientIds.length} clients have been updated`
            })
            this.fetchFilterResult()
          }
        })
        .then(() => {
          this.toggleBulkActions()
          return
        })
      }      
    })
  }

  bulkActionUpdateInternalNoteUpdateValue = async(text) => {
    this.setState({bulkActionsInternalNote: text.target.value})
  }

  bulkActionCreateInternalNote = async (note) => {

    this.fetchBulkActionUsers().then((payload) => {
      
      if (!payload.data.users.length) {
        notification.warning({
          message: `No clients found.  No clients updated.`
        })
        this.toggleBulkActions()
        return        
      } else {
        const clientIds = payload.data.users.map(m => m.client_id)
        notification.success({
          message: `Adding an internal note for ${clientIds.length} clients ...`
        })        
        fetch(`${SUBURBANJUNGLE_API_HOST}/clients/bulk/internal-note`, {
          method: 'POST',
          body: JSON.stringify({
            clientIds,
            note: this.state.bulkActionsInternalNote
          }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': cookies.get('Authorization')
          }
        })
        .then((res) => res.json())
        .then((res) => {
          this.toggleBulkActions()
          if (!res.status) {
            notification.warning({
              message: `An error occurred.  Please contact support.`
            })
          } else {
            notification.success({
              message: `Internal notes have been created for ${clientIds.length} clients.`
            })
            
            this.fetchFilterResult()
          }
        }).then(() => {
          this.toggleBulkActions()
          return
        })
      }      
    })
  }

  /**
   * Build a CSV of the current filtered list of Clients
   * 
   * Built in the client rather than redirecting to a download link directly from the API because:
   * - URL and query params for download endpoint are outside of context of this component
   * - This approach will work with any future Client-filtering changes
   */
  exportDataCSV = async () => {
    
    const downloadFilename = `clients.export.${moment().format('MM-DD-YYYY--HH-mm')}.csv`
    const currentFilteredUsers = await this.fetchAlldata()

    const headerRowColumnNames = [
      'id', 
      'email',
      'first_name',
      'last_name',
      'date_created',
      'current_location',
      'latest_activity',
      'price_range',
      'status',
      'suburb'
    ]
    let maxStrategists = 0;

    const rows = currentFilteredUsers.users.map(d => {
      
      const row = [
        d.client_id, 
        d.client_email,
        d.client_first_name,
        d.client_last_name,
        moment(d.client_created).format(),
        d.client_current_location,
        moment(d.latest_activity).format(),
        d.price_range,
        d.status,
        d.suburb_abb
      ]

      if (d.follow_up_date.length > maxStrategists) maxStrategists = d.follow_up_date.length

      for (let s = 0; s < d.follow_up_date.length; s++) {
        row.push(d.follow_up_date[s].name),
        row.push(d.follow_up_date[s].follow_up_date)
      }
      return row.join(',')
    })

    for (let m = 0; m < maxStrategists; m++) {
      headerRowColumnNames.push(`strategist_${m+1}`, `follow_up_date_${m+1}`)
    }
    const headerRow = headerRowColumnNames.join(',')

    rows.unshift(headerRow)
    const downloadCSVData = rows.join("\n")
    
    const downloadLink = document.getElementById("exportDataCSV")
    downloadLink.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(downloadCSVData)}`)
    downloadLink.setAttribute('download', downloadFilename)
    downloadLink.click()
    
  }
  

  filterStrategist = (id, type, e) => {

    let { startDate, endDate, selectedClientStatus, selectedClientAgents, selectedClientStrategists, selectedClientTimings, selectedClientSuburbs, selectedClientTowns, selectedClientBizDevCredits } = this.state

    if (type === "filterStatusHead") {
      selectedClientStatus = filter(selectedClientStatus, status => status !== id)
    }
    if (type === "filterStrategistHead") {
      selectedClientStrategists = filter(selectedClientStrategists, status => status !== id)
    }
    if (type === "filterTimingHead") {
      selectedClientTimings = filter(selectedClientTimings, status => status !== id)
    }
    if (type === "filterSuburbsHead") {
      selectedClientSuburbs = filter(selectedClientSuburbs, status => status !== id)
    }
    if (type === "filterTownsHead") {
      selectedClientTowns = filter(selectedClientTowns, town => town !== id)
    }
    
    if (type === "filterBizDevCreditsHead") {
      selectedClientBizDevCredits = filter(selectedClientBizDevCredits, bdc => bdc !== id)
    }

    if (type === "filterDateHead") {
      this.state.startDate = ""
      this.state.endDate = ""
      startDate = this.state.startDate
      endDate = this.state.endDate
    }

    if (type == 'filterAgentHead') {
      selectedClientAgents = filter(selectedClientAgents, agent => agent !== id)
    }
    
    this.filterUserRef.current.resetHeadFilterParams({ 
      selectedClientStatus, 
      selectedClientStrategists, 
      selectedClientTimings, 
      selectedClientSuburbs,
      selectedClientAgents,
      selectedClientTowns,
      selectedClientBizDevCredits,
      startDate,
      endDate 
    })
    
    if (this.newClientRef.current) this.newClientRef.current.setfilterParams({
      clientStatus: selectedClientStatus, 
      clientStrategist: selectedClientStrategists, 
      clientTiming: selectedClientTimings, 
      clientSuburb: selectedClientSuburbs, 
      clientAgent: selectedClientAgents,
      clientTown: selectedClientTowns,
      clientBizDevCredit: selectedClientBizDevCredits,
      startDate, 
      endDate
    })
    
    if (this.allClientRef.current) this.allClientRef.current.setfilterParams({
      clientStatus: selectedClientStatus, 
      clientStrategist: selectedClientStrategists, 
      clientTiming: selectedClientTimings, 
      clientSuburb: selectedClientSuburbs, 
      clientAgent: selectedClientAgents,
      clientTown: selectedClientTowns,
      clientBizDevCredit: selectedClientBizDevCredits,
      startDate, 
      endDate
    })
    
    this.setState({ 
      filteredStrategists: selectedClientStrategists, 
      filteredTimings: selectedClientTimings,
      filteredAgents: selectedClientAgents,
      filteredTowns: selectedClientTowns,
      filteredBizDevCredits: selectedClientBizDevCredits,
      selectedClientStatus, 
      selectedClientStrategists, 
      selectedClientTimings,
      selectedClientSuburbs, 
      selectedClientAgents,
      selectedClientTowns,
      selectedClientBizDevCredits,
      startDate, 
      endDate
    })

  }

  // when click on userfilter set filter data from filter user
  setfilterParams = (data) => {
    const { clientStatus, filteredStrategists, filteredAgents, filteredTimings, filteredSuburb, filteredTowns, filteredBizDevCredits, startDate, endDate, filterCount, searchCurrentLocation } = data
    this.setState({
      clientStatus,
      client: filteredStrategists,
      clientSuburb: filteredSuburb,
      clientAgent: filteredAgents,
      clientTown: filteredTowns,
      clientBizDevCredit: filteredBizDevCredits,
      clientCurrentLocation: searchCurrentLocation,
      clientStrategist: filteredStrategists,
      clientTiming: filteredTimings,
      selectedClientStatus: clientStatus,
      selectedClientStrategists: filteredStrategists,
      selectedClientTimings: filteredTimings,
      selectedClientSuburbs: filteredSuburb,
      selectedClientAgents: filteredAgents,
      selectedClientTowns: filteredTowns,
      selectedClientBizDevCredits: filteredBizDevCredits,
      filterCount: filterCount ? filterCount : 0,
      startDate: startDate ? moment.utc(startDate).format('YYYY-MM-DD') : "",
      endDate: endDate ? moment.utc(endDate).format('YYYY-MM-DD') : ""
    }, () => this.fetchFilterResult())
    if (this.newClientRef.current) this.newClientRef.current.setfilterParams(data)
    if (this.allClientRef.current) this.allClientRef.current.setfilterParams(data)
    this.forceUpdate()
  }
  formatNumber = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // Determine whether or not to show the Strategist column
  showStrategistColumn = (currentUser, bizDevCreditFilter) => {
    const isAdmin = currentUser && currentUser.role && currentUser.role.includes('admin')
    const isStrategist = currentUser && currentUser.role && currentUser.role === 'strategist'
    const isUsingBizDevCreditFilter = bizDevCreditFilter && bizDevCreditFilter.length > 0
    return isAdmin || (isStrategist && isUsingBizDevCreditFilter)    
  }

  showCurrentLocationColumn = (currentLocationFilter) => {
    return currentLocationFilter && currentLocationFilter != ''
  }

  clientIsReadOnly = (client, currentUser) => {
    const isAdmin = currentUser && currentUser.role && currentUser.role.includes('admin')
    const strategistIds = client.strategists.map(s => s.id)
    if (currentUser && currentUser.id) {
      if (isAdmin) return false
      if (strategistIds.includes(currentUser.id)) return false
    }
    return true
  }

  render() {
    
    const { clientsData, filterParams } = this.props;
    const { clientType, startDate, endDate, showBulkActions, showBulkActionsFollowUpDate, showBulkActionsInternalNote, clientCurrentLocation, isLoading, filterCount, selectedClientBizDevCredits } = this.state;
    const clientStatus = filterParams && filterParams.data && filterParams.data.statuses;
    const clientStrategists = filterParams && filterParams.data && filterParams.data.strategists;
    const clientAgents = filterParams && filterParams.data && filterParams.data.agents;
    const clientTowns = filterParams && filterParams.data && filterParams.data.towns;
    const clientBizDevCredits = filterParams && filterParams.data && filterParams.data.bizDevCredits;
    const clientTimings = this.state.timingsData;
    const clientSuburbs = filterParams && filterParams.data && filterParams.data.suburbs;
    const { resultEntry } = this.state;
    const allClients = get(clientsData, `user_counts.all_clients`, 0);
    const myClients = get(clientsData, `user_counts.my_clients`, 0);
    const currentUrl = get(this.props, "location.pathname", "")
    const user = cookies.get("user") || {};
    const userCanSeeBulkActions = user.can_copy_emails || user.role === 'admin'
    const userCanSeeExport = selectedClientBizDevCredits && selectedClientBizDevCredits.length > 0
    
    return (
      <div className="layout-has-sidebar">
        <AdministrationSidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <main className="dashboard-layout-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-2">
                <h2 className="heading-title">
                  <span className="heading-icon"><ClientsColorIcon className="" /></span>
                  Clients
                </h2>
              </div>

              <div className="col-md-10">
                <div className="filter__result__section">

                  {userCanSeeExport && 
                    <a onClick={this.fetchExportDataUrl} className="btn btn__btn btn-dark btn__add__new__client">Export Clients</a>
                  }
                  
                  {userCanSeeBulkActions &&

                    <Popover
                      content={
                        <div className="filter">
                          <div className="filter__header__note">
                            (Bulk actions operate on all Clients matching the current filters)
                          </div>
                          <div className="filter__body">
                            <ul className="filter__list">x``

                              <li>
                                <a className="text-black" onClick={this.bulkActionCopyEmails}>
                                  Copy Emails
                                </a>
                              </li>

                              <li>                                
                                <div className='bulk-actions-follow-up-date'>
                                  <a className="text-black" onClick={this.toggleBulkActionFollowUpDate}>Update Follow Up Dates</a>
                                    { showBulkActionsFollowUpDate && 
                                      <DatePicker 
                                        className="bulk-actions-follow-up-date-datepicker"
                                        placement="topRight"
                                        onChange={this.bulkActionSetFollowUpDate.bind(this)}
                                      />
                                    }                                  
                                </div>
                              </li>

                              <li>                                
                                <div className='bulk-actions-internal-note'>
                                  <a className="text-black" onClick={this.toggleBulkActionInternalNote}>Add Internal Note</a>
                                  { showBulkActionsInternalNote &&
                                    <div className="bulk-actions-internal-note">
                                      <TextArea onChange={this.bulkActionUpdateInternalNoteUpdateValue.bind(this)} placeholder="Add Internal Note to all visible Clients" />
                                      <Button onClick={this.bulkActionCreateInternalNote.bind(this)} >Add Internal Note</Button>
                                    </div>
                                  }
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      }
                      placement="bottom"
                      className="filter"
                      overlayClassName="filter__overlay"
                      open={showBulkActions}>
                      <Button className="btn btn__btn btn-dark btn__add__new__client" onClick={this.toggleBulkActions} type="primary">Bulk Actions</Button>
                    </Popover>
                  }

                  <Link to={'/strategist/add-client'} className="btn btn__btn btn-dark btn__add__new__client">Add New Client</Link>
                  {/* This is for filter section */}
                  
                  <FilterUser 
                    ref={this.filterUserRef}
                    resultEntry={resultEntry} 
                    {...this.props} 
                    setfilterParams={this.setfilterParams} 
                    timings={this.state.timingsData} />
                  {/* End filter section */}
                  <SelectPageResult handlePageChange={this.handleResultChange} />
                </div>
              </div>
            </div>
            {/*<ExportClients */}
            {/*  dataSource = { this.fetchAlldata } */}
            {/*  disabled = { !filterCount && (!startDate && !endDate) } */}
            {/*  hide = { !(clientsData.users || []).length || user.role !== 'superadmin' && user.role !== 'admin' } */}
            {/*/>*/}
            <div className="mob__view__container mob__view__control">
              <div className="col-md-12">
                <select
                  className="form-control custom__select mt__20 mb__20"
                  onChange={this.changePropsForMobile.bind(this)}
                  value={clientType}
                >
                  <option value="my_clients">New Clients (Created in last 48 hours)</option>
                  <option value="all_clients">All Clients</option>
                </select>

                <ul className="filter__list__flitered">
                  {clientStatus && map(clientStatus, (status, index) => {
                    const findSelected = find(this.state.selectedClientStatus, o => o === status.name)
                    if (findSelected && size(findSelected)) {
                      return (<li key={index}>{status.name}<span onClick={this.filterStrategist.bind(this, status.name, 'filterStatusHead')}><CloseIcon className="close-icon" /></span></li>)
                    }
                  })}
                  {clientTimings && map(clientTimings, (timing, index) => {
                    const findSelected = find(this.state.selectedClientTimings, o => o === timing.id)
                    if (findSelected) {
                      return (<li key={index}>{timing.name}<span onClick={this.filterStrategist.bind(this, timing.id, 'filterTimingHead')}><CloseIcon className="close-icon" /></span></li>)
                    }
                  })}
                  {clientStrategists && map(clientStrategists, (strategist, index) => {
                    const findSelected = find(this.state.selectedClientStrategists, o => o === strategist.id)
                    if (findSelected) {
                      return (<li key={index}>{strategist.strategist_name}<span onClick={this.filterStrategist.bind(this, strategist.id, 'filterStrategistHead')}><CloseIcon className="close-icon" /></span></li>)
                    }
                  })}
                  {clientSuburbs && map(clientSuburbs, (suburb, index) => {
                    const findSelected = find(this.state.selectedClientSuburbs, o => o === suburb.id)
                    if (findSelected) {
                      return (<li key={index}>{suburb.name}<span onClick={this.filterStrategist.bind(this, suburb.id, 'filterSuburbsHead')}><CloseIcon className="close-icon" /></span></li>)
                    }
                  })}
                  {clientTowns && map(clientTowns, (town, index) => {
                    const findSelected = find(this.state.selectedClientTowns, o => o === town.id)
                    if (findSelected) {
                      return (<li key={index}>Town: {town.name}<span onClick={this.filterStrategist.bind(this, town.id, 'filterTownsHead')}><CloseIcon className="close-icon" /></span></li>)
                    }
                  })}
                  {clientBizDevCredits && map(clientBizDevCredits, (bdc, index) => {
                    const findSelected = find(this.state.selectedClientBizDevCredits, o => o === bdc.id)
                    if (findSelected) {
                      return (<li key={index}>BizDev Credit: {bdc.name}<span onClick={this.filterStrategist.bind(this, bdc.id, 'filterBizDevCreditsHead')}><CloseIcon className="close-icon" /></span></li>)
                    }
                  })}
                  {clientCurrentLocation && 
                    <li key='currentLocation'>Current Location <span onClick={this.filterStrategist.bind(this, clientCurrentLocation, 'filterCurrentLocationHead')}><CloseIcon className="close-icon" /></span></li>
                  }
                </ul>
              </div>
            </div>

            <div className="desktop__view">
              <div className="row">
                <div className="col-md-12">
                  <div className="tabs__control">
                    <button
                      className={`btn btn-link ${(currentUrl === '/strategist/clients/new-clients' || currentUrl === '/strategist/clients') && 'active'}`}
                      onClick={() => this.handleRouteChange('new-clients')}>
                      New Clients(Created in last 48 hours) <span>{this.formatNumber(myClients)} Clients</span>
                    </button>
                    <button
                      className={`btn btn-link ${currentUrl === '/strategist/clients/all-clients' && 'active'}`}
                      onClick={() => this.handleRouteChange('all-clients')}>
                      All Clients <span>{this.formatNumber(allClients)} Clients</span>
                    </button>
                  </div>
                </div>
              </div>
              <ul className="filter__list__flitered">
                {clientStatus && map(clientStatus, (status, index) => {
                  const findSelected = find(this.state.selectedClientStatus, o => o === status.name)
                  if (findSelected && size(findSelected)) {
                    return (<li key={index}>Status: {status.name}<span onClick={this.filterStrategist.bind(this, status.name, 'filterStatusHead')}><CloseIcon className="close-icon" /></span></li>)
                  }
                })}
                {clientTimings && map(clientTimings, (timing, index) => {
                  const findSelected = find(this.state.selectedClientTimings, o => o === timing.name)
                  if (findSelected) {
                    return (<li key={index}>{timing.name}<span onClick={this.filterStrategist.bind(this, timing.name, 'filterTimingHead')}><CloseIcon className="close-icon" /></span></li>)
                  }
                })}
                {clientStrategists && map(clientStrategists, (strategist, index) => {
                  const findSelected = find(this.state.selectedClientStrategists, o => o === strategist.id)
                  if (findSelected) {
                    return (<li key={index}>Strategist: {strategist.strategist_name}<span onClick={this.filterStrategist.bind(this, strategist.id, 'filterStrategistHead')}><CloseIcon className="close-icon" /></span></li>)
                  }
                })}
                {clientSuburbs && map(clientSuburbs, (suburb, index) => {
                  const findSelected = find(this.state.selectedClientSuburbs, o => o === suburb.id)
                  if (findSelected) {
                    return (<li key={index}>Suburb: {suburb.name}<span onClick={this.filterStrategist.bind(this, suburb.id, 'filterSuburbsHead')}><CloseIcon className="close-icon" /></span></li>)
                  }
                })}
                {(startDate !== "" && endDate !== "") &&
                  (<li>{moment.utc(startDate).format('MM/DD/YYYY')}-{moment.utc(endDate).format('MM/DD/YYYY')}<span onClick={this.filterStrategist.bind(this, "date", 'filterDateHead')}><CloseIcon className="close-icon" /></span></li>)
                }
                {clientAgents && map(clientAgents, (agent, index) => {
                  const findSelected = find(this.state.selectedClientAgents, a => a === agent.id)
                  if (findSelected) {
                    return (<li key={index}>Agent: {agent.agent_name}<span onClick={this.filterStrategist.bind(this, agent.id, 'filterAgentHead')}><CloseIcon className="close-icon" /></span></li>)
                  }
                })}
                {clientTowns && map(clientTowns, (town, index) => {
                  const findSelected = find(this.state.selectedClientTowns, t => t === town.id)
                  if (findSelected) {
                    return (<li key={index}>Town: {town.name}<span onClick={this.filterStrategist.bind(this, town.id, 'filterTownsHead')}><CloseIcon className="close-icon" /></span></li>)
                  }
                })}
                {clientBizDevCredits && map(clientBizDevCredits, (bdc, index) => {
                  const findSelected = find(this.state.selectedClientBizDevCredits, o => o === bdc.id)
                  if (findSelected) {
                    return (<li key={index}>BizDev Credit: {bdc.name}<span onClick={this.filterStrategist.bind(this, bdc.id, 'filterBizDevCreditsHead')}><CloseIcon className="close-icon" /></span></li>)
                  }
                })}
                {clientCurrentLocation && 
                  <li key='currentLocation'>Current Location: {clientCurrentLocation} <span onClick={this.filterStrategist.bind(this, clientCurrentLocation, 'filterCurrentLocationHead')}><CloseIcon className="close-icon" /></span></li>
                }                           
              </ul>

            </div>
            {/* exact routes for show tab on strategist/client url */}
            <ClientContext.Provider 
              value={{isClientDataFetching: this.state.isClientDataFetching}}>
            <Switch>             
              <Route exact path={`/strategist/clients`}>
                <NewClients {...this.props}
                  ref={this.newClientRef}
                  fetchFilterResult={this.fetchFilterResult}
                  resultEntry={this.state.resultEntry}
                  isLoading={this.state.isLoading}
                  showStrategistColumn={this.showStrategistColumn}
                  showCurrentLocationColumn={this.showCurrentLocationColumn}
                  clientIsReadOnly={this.clientIsReadOnly}
                />
              </Route>
              <Route exact path={`/strategist/clients/new-clients`}>
                <NewClients {...this.props}
                  ref={this.newClientRef}
                  fetchFilterResult={this.fetchFilterResult}
                  resultEntry={this.state.resultEntry}
                  isLoading={this.state.isLoading}
                  showStrategistColumn={this.showStrategistColumn}
                  showCurrentLocationColumn={this.showCurrentLocationColumn}
                  clientIsReadOnly={this.clientIsReadOnly}         
                />
              </Route>
              <Route exact path={`/strategist/clients/all-clients`}>
                <AllClients {...this.props}
                  ref={this.allClientRef}
                  fetchFilterResult={this.fetchFilterResult}
                  resultEntry={this.state.resultEntry}
                  isLoading={this.state.isLoading}
                  showStrategistColumn={this.showStrategistColumn}
                  showCurrentLocationColumn={this.showCurrentLocationColumn}
                  clientIsReadOnly={this.clientIsReadOnly}              
                />
              </Route>
            </Switch>
              </ClientContext.Provider>
          </div>
        </main>
      </div>
    )
  }
}
