/* eslint-disable camelcase */
import React, { PureComponent, Fragment } from 'react';
import { Switch, Spin, notification } from 'antd';
import ReactModal from 'react-modal'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import _, { get, map, compact, isEmpty, uniq, filter } from 'lodash';
import PhoneInput from 'react-phone-input-2';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import Cookies from 'universal-cookie';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import 'react-phone-input-2/dist/style.css';

import './styles.scss';
import TopNavigation from '../../../../components/TopNavigation/container';
import AdministrationSidebarNavigation from '../../../../components/AdministrationSidebarNavigation/component';
import { BackArrowIcon, CameraIcon, CloseIcon, PdfIcon, CaretRightIcon } from '../../../../components/icons';
import Validation from '../../../../config/validation';
import config from '../../../../config';
import SelectTown from './partials/SelectTown';
import SuburbsCheckboxList from './partials/SuburbsCheckboxList';
import PayoutAdditionalFields from './partials/PayoutAdditionalFields';
import MinMaxPrice from './partials/MinMaxPrice';
import SelectStrategist from './partials/SelectStrategist';
import { multiSelectStyles, initialValues} from './variables';
import { roleListOption, displayUserFormFieldForCurrentUserAndSelectedRole as displayFieldForRole } from '../AddUser/variables'
import { SUCCESS, ERROR } from '../../../../constants/phase';
import { createStringAvatar, logoutInvalidRequest } from '../../../../utils/helper';
import Loader from '../../../../components/Loader';
import { fetch } from "../../../../utils";
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME_V2
const SUBURBANJUNGLE_API_HOST = process.env.SUBURBANJUNGLE_API_HOST
const cookies = new Cookies();

const API_HOSTNAME = process.env.API_HOSTNAME;

let _user;
const roleList = [
  { id: '1', key: 'superadmin', name: 'superadmin' },
  { id: '2', key: 'admin', name: 'admin' },
  { id: '3', key: 'strategist', name: 'strategist' },
  { id: '4', key: 'agent', name: 'agent' },
  { id: '6', key: 'local', name: 'local' },
  { id: '7', key: 'former-local', name: 'former local' },
  { id: '8', key: 'former-agent', name: 'former agent' },
  {
    id: '9',
    key: 'former-strategist-inactive',
    name: 'former strategist (inactive)'
  },
  { id: '10', key: 'business development', name: 'business development' },
  {
    id: '11',
    key: 'former business development',
    name: 'former business development'
  }
];
export default class AddUserComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      countryCode: '+1',
      isLoading: true,
      showPDF: false,
      deleteClientModal: false,
      userDataEdit: {},
      townOptions: [],
      file: '',
      searchTownOption: [],
      updateCount: 0,
      toRoute: '',
      searchTownOptionMain: {},
      isShow: false,
      userId: null,
      displayMessage: false
    };
  }

  componentDidMount = () => {
    _user = cookies.get('user');
    document.title = 'Jungler: Edit Jungler User';
    const userIdParam = get(this, 'props.match.params.id', '');
    this.state.userId = userIdParam
    this.setState({ toRoute: get(this, 'props.location.state.pathnameToRoute', '') })
    if (userIdParam) {
      this.state.checked = [];
      window.scrollTo(0, 0);
      const _usrID = userIdParam;
      if (_usrID) {
        const usrdata = {};
        usrdata.user_id = _usrID;
        this.fetchUser(usrdata)
        // this.props.getUSerProfileByID(usrdata);
        this.props.getFilterParams();
      }
    } else {
      this.props.history.push(get(this, 'props.location.state.pathnameToRoute', ''));
    }
  };

  componentDidUpdate(preProps, preState) {
    const { location } = this.props
    let id = get(this, 'props.match.params.id', '')
    if (location.pathname !== preProps.location.pathname) {
      const usrdata = {}
      usrdata.user_id = id
      this.fetchUser(usrdata)
    }
  }

  handleClickDelete = () => {
    const clientId = this.state.userDataEdit.id;
    if (clientId && clientId !== '') {
      this.props.deleteClient({ client_id: clientId })
    }
  }
  openModal(arg) {
    // this.setState({ modal: true })
    this.setState({ isLoading: true })
    const user = cookies.get('user');
    const data = {}
    const userIdParam = get(this, 'props.match.params.id', '');
    // data.agent_id =  "16246"
    data.agent_id = userIdParam
  }
  fetchUser = async (data) => {

    const { value } = await this.props.getUserProfileByIDEditProfile(data)
    if (value.user.role == "agent") {
      this.openModal(this, 'onLoad');
    }

    value.user.is_limit = !value.user.is_limit
    let obj = {}
    const userDataMain = get(value, 'user', {});
    if (_user && userDataMain && _user.role === 'strategist' && userDataMain.role === 'strategist') {
      this.setState({ "isShow": true })
    }
    obj.userDataEdit = userDataMain;
    const suburbAr = [];
    userDataMain.suburbs.map((s, i) => {
      suburbAr.push(s.id);
    });
    let strategistsOptions = [];
    if (_user && _user.id) {
      strategistsOptions = compact(
        map(get(userDataMain, 'impersonate_strategist', []), strategist => {
          if (strategist && strategist.id && _user.id !== strategist.id) {
            return {
              label: `${strategist.first_name} ${strategist.last_name}`,
              value: strategist.id
            };
          }
        })
      );
    }

    userDataMain.seletedSearchByStrategist = strategistsOptions
      ? strategistsOptions
      : [];
    const selectedTowns = userDataMain.towns;
    const selectedSearchTown = [];
    !isEmpty(selectedTowns) &&
      selectedTowns.map(townVal => {
        if (selectedSearchTown.indexOf(townVal.id) === -1) {
          selectedSearchTown.push({ value: townVal.id, label: townVal.name });
        }
      });
    // selectedSearchTown
    userDataMain.towns = suburbAr;
    userDataMain.strategistAssignmentRules = value.user.strategist_assignment_rules
    obj.countryCode = get(userDataMain, 'country_code', '+1');
    userDataMain.selectedSearchTown = selectedSearchTown;
    obj.userDataEdit = userDataMain;
    this.setState({ isLoading: true });
    this.props.getTowns({ suburb_arr: suburbAr, client_id: this.state.userId });
    this.setState(obj)
    this.forceUpdate()
  }

  static getDerivedStateFromProps(props, state) {
    const obj = {};

    if (props.dataPhase === 'success') {
      props.history.push(state.toRoute);
    }
    if (
      props.impersonatePhase === 'success' &&
      props.impersonateData &&
      props.impersonateData.magic_link
    ) {
      const i_user = cookies.get('user')
      const i_Authorization = cookies.get('Authorization')
      let expires = new Date()
      localStorage.clear()
      expires.setMonth(expires.getMonth() + 2)
      expires = new Date(expires)
      cookies.set('i_Authorization', i_Authorization, { path: '/', expires, secure: true, sameSite: 'strict' })
      cookies.set('i_user', JSON.stringify(i_user), { path: '/', expires, secure: true, sameSite: 'strict' })
      const magicLink = props.impersonateData.magic_link
      const pathname = `/client-login/token?validate=${magicLink}`
      props.clearPhase()
      document.cookie =
        'Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      document.cookie = 'user =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      localStorage.clear()
      window.location.href = pathname
      // props.history.push({ pathname })
    }
    if (props.createJunglerUserPhase === SUCCESS) {
      props.clearJunglerUserPhase();
      if (get(props, 'junglerUserPhaseData.status')) {
        if (state.displayMessage === true) {
          notification.success({
            message: 'Details Updated!'
          });
          state.displayMessage = false
        }
        if (state.updateCount > 0) {
          if (state.toRoute) {
            props.history.push(state.toRoute);
          }
          obj.isLoading = false
        }
      }
      if (!get(props, 'junglerUserPhaseData.status')) {
        notification.error({
          message: get(props, 'junglerUserPhaseData.message')
        });
        obj.isLoading = false
      }
    }

    if (props.createJunglerUserPhase === ERROR) {
      props.clearJunglerUserPhase();
      notification.error({
        message: 'An error occured'
      });
      obj.isLoading = false
    }

    // if (props.junglerUserPhase === SUCCESS) {
    //   props.clearPhase();
    //   props.clearJunglerUserPhase();
    //   const userDataMain = get(props, 'userDataMain.user', {});
    //   obj.userDataEdit = userDataMain;
    //   const suburbAr = [];
    //   userDataMain.suburbs.map((s, i) => {
    //     suburbAr.push(s.id);
    //   });
    //   let strategistsOptions = [];
    //   if (_user && _user.id) {
    //     strategistsOptions = compact(
    //       map(get(userDataMain, 'impersonate_strategist', []), strategist => {
    //         if (strategist && strategist.id && _user.id !== strategist.id) {
    //           return {
    //             label: `${strategist.first_name} ${strategist.last_name}`,
    //             value: strategist.id
    //           };
    //         }
    //       })
    //     );
    //   }

    //   userDataMain.seletedSearchByStrategist = strategistsOptions
    //     ? strategistsOptions
    //     : [];
    //   const selectedTowns = userDataMain.towns;
    //   const selectedSearchTown = [];
    //   !isEmpty(selectedTowns) &&
    //     selectedTowns.map(townVal => {
    //       if (selectedSearchTown.indexOf(townVal.id) === -1) {
    //         selectedSearchTown.push({ value: townVal.id, label: townVal.name });
    //       }
    //     });
    //   // selectedSearchTown
    //   userDataMain.towns = suburbAr;
    //   obj.countryCode = get(userDataMain, 'country_code', '+1');
    //   userDataMain.selectedSearchTown = selectedSearchTown;
    //   obj.userDataEdit = userDataMain;
    //   obj.isLoading = false;
    //   props.getTowns({ suburb_arr: suburbAr });
    // }

    if (props.townPhase === SUCCESS) {
      props.clearPhase();
      const userDataMain = state.userDataEdit;
      // obj.searchTownOptionMain = map(
      //   get(props, 'townData.data', []),
      //   suburb => {
      //     return { label: suburb.name, value: suburb.id };
      //   }
      // );
      const d = map(get(props, 'townData.data', []), suburb => {
        return { label: suburb.name, value: suburb.id };
      });
      obj.searchTownOptionMain = { searchTownOption: d };
      obj.isLoading = false
    }
    if (get(props, 'deleteClientdata.status', '') === true) {
      props.clearPhase()
      props.history.push(`/strategist/jungler-users`)
    } else if (get(props, 'deleteClientdata.status', '') === false) {
      props.clearPhase()
      props.history.push(`/strategist/jungler-users`)
    }

    return obj;
  }
  setIsLimitValue = (role, setFieldValue) => {
    if (role === 'agent') {
      setFieldValue('is_limit', true)
    }
  }

  updateImpersonateUserData = async (values) => {
    let data = {
      strategist_id: this.state.userDataEdit.id
    }
    if (values['isImpersonate']) {
      let strategist = map(
        get(values, 'seletedSearchByStrategist', []),
        strategist => strategist.value
      );
      data.user_ids = strategist
    }
    if (!values['isImpersonate']) {
      data.user_ids = []
    }
    fetch(`${SUBURBANJUNGLE_API_HOST}/users/impersonate/strategist/${data.strategist_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).catch(logoutInvalidRequest)
  }

  handleSubmit = async values => {

    await this.updateImpersonateUserData(values)
    let img = get(values, 'avatar', '')
    if (this.state.file) {
      const res = await this.submitData();
      if (res) {
        this.setState({ file: '', avatar: '' })
        img = get(res, 'data');
      }
    }
    values.calendar_id = get(values, "calendar_id")

    //a true value in is_limit means that the user is not limited, the text of this field is backwards so the value will be handled backwards on the front end
    const isLimit = !get(values, 'is_limit', false)

    const data = {
      role: get(values, 'role', ''),
      first_name: get(values, 'first_name', ''),
      last_name: get(values, 'last_name', ''),
      description: get(values, 'description', ''),
      email: get(values, 'email', ''),
      time_zone: get(values, 'time_zone', ''),
      avatar: img,
      country_code: get(values, 'country_code', '+1'),
      phone_mobile: get(values, 'phone_mobile', ''),
      suburbs_arr: get(values, 'towns', []),
      designation: get(values, 'designation', ''),
      firm_name: get(values, 'firm_name', ''),
      hourly_amount: get(values, 'hourly_amount', ''),
      conferenceContryCode: get(values, 'conferenceContryCode', ''),
      phone_work: get(values, 'phone_work', ''),
      price_min: get(values, 'price_min', ''),
      price_max: get(values, 'price_max', ''),
      is_schedule_on: get(values, 'is_schedule_on', false),
      is_copy_email: get(values, 'is_copy_email', false),
      calendar_id: get(values, 'calendar_id', 0),
      is_limit: isLimit,
      update_type: 'basic_info',
      agent_town: get(values, 'agent_town', ''),
      business_profile: get(values, 'business_profile', ''),
      zillow_profile: get(values, 'zillow_profile', ''),
      is_copy_email: get(values, 'is_copy_email', false)
    };

    data.id = this.state.userDataEdit.id

    if (get(values, 'towns', [])) {
      data.suburbs_arr = get(values, 'towns', []);
      data.update_type = 'suburb_info';
    }

    if (get(values, 'selectedRole', '') === 'agent') {
      data.agent_town = get(values, 'agent_town', ''),
        data.firm_name = get(values, 'firm_name', '');
      data.designation = get(values, 'designation', '');
      data.email = get(values, 'email', '');
      data.is_limit = isLimit;
      data.country_code = get(values, 'country_code', '+1');
      data.phone_mobile = get(values, 'phone_mobile', '');
      data.towns_arr = map(
        get(values, 'selectedSearchTown', []),
        town => town.value
      );
      data.update_type = 'suburb_info';
    }

    if (get(values, 'selectedRole', '') === 'local') {
      data.update_type = "suburb_info";
      data.towns_arr = map(
        get(values, "selectedSearchTown", []),
        (town) => town.value
      );
    }

    if (get(values, 'selectedRole', '') === 'strategist') {
      data.country_code = get(values, 'country_code', '');
      data.phone_mobile = get(values, 'phone_mobile', '');
      data.hourly_amount = get(values, 'hourly_amount', '');
      data.designation = get(values, 'designation', '');
      data.conferenceContryCode = get(values, 'conferenceContryCode', '');
      data.phone_work = get(values, 'phone_work', '');
      // data.price_min = get(values, 'price_min', '');
      // data.price_max = get(values, 'price_max', '');
      data.is_schedule_on = get(values, 'is_schedule_on', false);
      data.is_copy_email = get(values, 'is_copy_email', false);
      data.calendar_id = get(values, 'calendar_id', 0);
      data.isImpersonate = get(values, 'isImpersonate', '');
      data.strategist = map(
        get(values, 'seletedSearchByStrategist', []),
        strategist => strategist.value
      );
    }

    if (get(_user, 'role', '') === 'superadmin' || get(_user, 'role', '') === 'admin') {
      data.pay_scale = get(values, 'pay_scale', '');
      data.deal_payout = get(values, 'deal_payout', '');
      data.deal_payout_amount = get(values, 'deal_payout_amount', 0);
      data.refferal_payout = get(values, 'refferal_payout', '');
      data.isImpersonate = get(values, 'isImpersonate', '');
      data.refferal_payout_amount = get(
        values,
        'refferal_payout_amount',
        0
      );
      data.hourly_amount = get(values, 'hourly_amount', 0)
    } else {
      if (this.state.userDataEdit.role === 'strategist' && _user.role === 'strategist') {
        data.pay_scale = this.state.userDataEdit.pay_scale ? this.state.userDataEdit.pay_scale : ''
        data.deal_payout = this.state.userDataEdit.deal_payout ? this.state.userDataEdit.deal_payout : ''
        data.deal_payout_amount = this.state.userDataEdit.deal_payout_amount ? this.state.userDataEdit.deal_payout_amount : 0
        data.refferal_payout = this.state.userDataEdit.refferal_payout ? this.state.userDataEdit.refferal_payout : ''
        data.refferal_payout_amount = this.state.userDataEdit.refferal_payout_amount ? this.state.userDataEdit.refferal_payout_amount : 0
        data.hourly_amount = this.state.userDataEdit.hourly_amount ? this.state.userDataEdit.hourly_amount : ''
      } else {
        data.pay_scale = get(values, 'pay_scale', '');
        data.deal_payout = get(values, 'deal_payout', '');
        data.deal_payout_amount = get(values, 'deal_payout_amount', 0);
        data.refferal_payout = get(values, 'refferal_payout', '');
        data.refferal_payout_amount = get(
          values,
          'refferal_payout_amount',
          0
        );
        data.hourly_amount = get(values, 'hourly_amount', 0);
      }
    }

    // data.strategist_assignment_rules = this.state.userDataEdit.strategistAssignmentRules

    this.props.createJunglerUser(data);
    this.state.displayMessage = true
    this.setState({ updateCount: 1 }, () => {
      data.update_type = data.update_type === 'basic_info' ? 'suburb_info' : 'basic_info'
      this.props.createJunglerUser(data);
      this.state.displayMessage = true
    })           
    // updateCount
  };

  handleAddJunglerUser = () =>
    this.props.history.push('/strategist/jungler-users');

  handleFileUpload = (e, setFieldValue) => {
    const _URL = window.URL || window.webkitURL;
    // let files = $("#profileImage")[0].files[0]
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      const reader = new FileReader();
      reader.onloadend = () => {
        img.src = _URL.createObjectURL(file);
        img.onload = () => {
          this.setState({ file, avatar: reader.result });
          setFieldValue('avatar', reader.result);
        };
      };
      const read = reader.readAsDataURL(file);
    }
  };

  handleChange = (event, setFieldValue) => {
    const { name, value } = event.target;
    this.setState({ [name]: value.trim() });
    setFieldValue(name, value);
  };

  // handle switch for change form view
  handleSwitch = (event, name, setFieldValue) => setFieldValue(name, event);
  
  // handleMetroAreaAndStrategistAssignmentRuleChanges = (metroAreaId, currentlySelectedMetroAreaIds, minMax = false, value=false, setFieldValue=false) => {

  //   // test if this call is setting just the price is being set for a rule
  //   const isRulePriceBeingSet = minMax !== false

  //   // cycle through all displayable towns
  //   const allMetroAreaIds =  get(this.props, 'filterParams.data.suburbs', []).map(s => s.id)
  //   let newMetroAreaIds = currentlySelectedMetroAreaIds
  //   let newStrategistAssignmentRules = this.state.userDataEdit.strategistAssignmentRules

  //   for (const currentMetroAreaId of allMetroAreaIds) {

  //     if (currentMetroAreaId === metroAreaId) {

  //       // If this is only toggling a metro area on or off, just update the the Metro Area List
  //       if (!isRulePriceBeingSet) {  
  //         if (currentlySelectedMetroAreaIds.includes(currentMetroAreaId)) {
  //           newMetroAreaIds = newMetroAreaIds.filter(m => m != currentMetroAreaId)
  //         } else {
  //           newMetroAreaIds.push(currentMetroAreaId)
  //         }
  //       }

  //       // Add/ update strategist assignment rules in state
  //       if (isRulePriceBeingSet) {
  //         let ruleToUpdate = newStrategistAssignmentRules.filter(r => r.suburb_id === currentMetroAreaId).pop()
  //         if (ruleToUpdate) {
  //           ruleToUpdate[(minMax === 'min' ? 'min' : 'max')] = parseInt(value)
  //           newStrategistAssignmentRules.map(currentRule => {
  //             return (currentRule.suburb_id === currentMetroAreaId ? ruleToUpdate : currentRule)
  //           })
  //         } else {
  //           newStrategistAssignmentRules.push({
  //             suburb_id: currentMetroAreaId,
  //             min: minMax === "min" ? parseInt(value) : null,
  //             max: minMax === "max" ? parseInt(value) : null,
  //           })
  //         }
  //       }
  //     }
  //   }


  //   this.setState({
  //     userDataEdit: {
  //       ...this.state.userDataEdit,
  //       strategistAssignmentRules: newStrategistAssignmentRules
  //     }
  //   })
  //   setFieldValue('towns', newMetroAreaIds)

  // }


  handleMultiSelectionOption = (options, event, setFieldValue) => {
    if (options) {
      const selectedOptions = map(options, option => option.value);
      setFieldValue(event, selectedOptions);
    }
  };


  // show hide delete client confirmation model
  toggleDeleteClientModal = () => this.setState({ deleteClientModal: !this.state.deleteClientModal })

  impersonateUser = userId => {
    this.setState({ isLoading: true });
    this.props.impersonateUser({ client_id: userId });
  };

  submitData = async () => {
    const formData = new FormData();
    formData.append('image', this.state.file);
    formData.append('user_id', _user.id);

    return await fetch(`${SUBURBANJUNGLE_API_HOST}/users/me/avatar`, {
      method: 'POST',
      body: formData
    }).then(res => res.json())
      .then(response => {
        if(response.status && response.data){
          return response;
        }
      }).catch(logoutInvalidRequest)
  };

  render() {
    const {
      searchTownOption,
      userDataEdit,
      isLoading,
      searchTownOptionMain,
      isShow,
      showPDF
    } = this.state;

    const timeZoneOption = config.timezonelist.map((val, index) => (
      <option key={index} value={val.key}>
        {val.name}{' '}
      </option>
    ));

    const townDataOption = map(
      get(this.props, 'filterParams.data.suburbs', []),
      suburb => {
        return { label: suburb.name, value: suburb.id };
      }
    );

    const priceBrackets = get(this.props, 'filterParams.data.price_brackets', [])
    const priceBracketDataOption = {
      price_min: priceBrackets.map(pb => parseInt(pb.price_min)).sort((a,b) => a-b).filter(pm => pm !== 1),
      price_max: priceBrackets.map(pb => parseInt(pb.price_max)).sort((a,b) => a-b).filter(pm => pm !== 0)
    }

    let strategistsOptions = [];
    if (_user) {
      strategistsOptions = compact(
        map(
          get(this.props, 'filterParams.data.strategists', []),
          strategist => {
            if (strategist && _user.id !== strategist.id) {
              return {
                label: strategist.strategist_name,
                value: strategist.id
              };
            }
          }
        )
      );
    }
    const rolesPreventedFromImpersonation = ['business development', 'former business development', 'former-agent', 'prospect-agent']
    const allowImpersonation =  !rolesPreventedFromImpersonation.includes(get(userDataEdit, 'role', ''))
    
    return (
      <div className="layout-has-sidebar">
        <AdministrationSidebarNavigation {...this.props} />
        <TopNavigation {...this.props} />
        <Spin indicator={<Loader />} size="large" spinning={isLoading}>
          <main className="dashboard-layout-content">
            <div className="container-fluid">
              <div className="client__details__header">
                <div className="row">
                  <div className="col-xs-12 col-sm-5 col-md-4">
                    <button
                      className="btn btn-back"
                      onClick={this.handleAddJunglerUser}
                    >
                      <BackArrowIcon className="arrow-icon" />
                      Back
                    </button>
                  </div>
                  <div className="col-xs-12 col-sm-7 col-md-8">
                    <div className="edit__user__head">
                      {
                        allowImpersonation && (
                          <button
                            disabled={isLoading}
                            onClick={() => this.impersonateUser(userDataEdit.id)}
                            type="button"
                            className="btn btn__btn btn-dark w__180 mr__15"
                          >
                            Impersonate User
                          </button>
                        )
                      }
                      <button
                        disabled={isLoading}
                        onClick={this.toggleDeleteClientModal}
                        type="button"
                        className="btn btn__btn btn-danger w__140">
                        Delete User
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <h1 className="title__section">Edit User</h1>
                </div>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    ...initialValues,
                    searchByStrategist: strategistsOptions,
                    creatorRole: get(_user, 'role', ''),
                    selectedRole: get(userDataEdit, 'role', ''),
                    ...searchTownOptionMain,
                    ...userDataEdit,
                    isImpersonate: !isEmpty(get(userDataEdit, 'seletedSearchByStrategist', [])) ? true : false
                  }}
                  validationSchema={Validation.editUser}
                  onSubmit={(values, { setSubmitting }) => {
                    // setSubmitting(true);
                    this.setState({ isLoading: true });
                    this.handleSubmit(values);
                  }}
                >
                  {({ values, errors, isSubmitting, setFieldValue }) => (
                    <Form noValidate>
                      <div className="col-md-10">
                        <div className="card">
                          <div className="card-body">
                            <div className="form-group material-textfield">
                              <Field
                                as="select"
                                onChange={e => {
                                  setFieldValue('role', e.target.value)
                                  setFieldValue('selectedRole', e.target.value)
                                  this.setIsLimitValue(e.target.value, setFieldValue)
                                }}
                                className="form-control custom__select material-textfield-input textfield-input-lg"
                                name="selectedRole"

                              >
                                <option value="">Select Role</option>
                                  {roleListOption(_user ? _user : '')}
                              </Field>
                              <label className="material-textfield-label label-lg">
                                Role <span className="text-danger">*</span>
                              </label>
                              <ErrorMessage
                                className="invalid-feedback"
                                name="selectedRole"
                                component="div"
                              />
                            </div>
                            {values.selectedRole && (
                              <Fragment>
                                <div className="form-group material-textfield">

                                  {displayFieldForRole(_user.role, values.selectedRole, 'image') && 
                                    <div className="d__flex align__items__center">
                                      <div className="avatar-text">
                                        Profile Photo <span> (1mb max)</span>
                                      </div>
                                      <div className="avatar avatar-lg">
                                        {/* eslint-disable-next-line no-nested-ternary */}
                                        {values.avatar ? (
                                          <img src={values.avatar} alt="" />
                                        ) : values.first_name ? (
                                          <span className="avatar-string">
                                            {createStringAvatar(
                                              `${values.first_name} ${values.last_name}`
                                            )}
                                          </span>
                                        ) : (
                                          <img
                                            src="../img/blank_profile_image.png"
                                            alt=""
                                          />
                                        )}
                                        <div className="upload-avatar-container">
                                          <input
                                            type="file"
                                            className="upload-avatar"
                                            accept="image/*"
                                            name="image"
                                            onChange={e =>
                                              this.handleFileUpload(
                                                e,
                                                setFieldValue
                                              )
                                            }
                                            id="file"
                                          />
                                          <CameraIcon />
                                        </div>
                                      </div>
                                    </div>
                                  }

                                </div>
                                <div className="row">

                                  {displayFieldForRole(_user.role, values.selectedRole, 'first_name') && 
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <Field
                                          type="text"
                                          className="form-control material-textfield-input textfield-input-lg"
                                          name="first_name"
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">
                                          First Name{' '}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="first_name"
                                          component="div"
                                        />
                                      </div>
                                    </div>                                  
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'last_name') &&
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <Field
                                          type="text"
                                          className="form-control material-textfield-input textfield-input-lg"
                                          name="last_name"
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">
                                          Last Name{' '}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="last_name"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                  }

                                </div>
                                <div className="row">

                                  {displayFieldForRole(_user.role, values.selectedRole, 'email') &&
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <Field
                                          type="text"
                                          className="form-control material-textfield-input textfield-input-lg"
                                          name="email"
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">Email <span className="text-danger">*</span></label>
                                        <ErrorMessage className="invalid-feedback" name="email" component="div" />
                                      </div>
                                    </div>
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'country_code') &&
                                    <div className="col-md-6">
                                      <div className="form-group material-textfield">
                                        <PhoneInput
                                          defaultCountry={'us'}
                                          value={values.country_code}
                                          autoFormat={false}
                                          name="country_code"
                                          disableAreaCodes={true}
                                          enableLongNumbers={false}
                                          onChange={event =>
                                            setFieldValue(
                                              'country_code',
                                              `+${event}`
                                            )
                                          }
                                          buttonClass="flag-textfield"
                                          required
                                        />
                                        <NumberFormat
                                          format="(###) ### #### #####"
                                          value={values.phone_mobile}
                                          name="phone_mobile"
                                          id="phone_mobile"
                                          className="form-control phone-textfield-input material-textfield-input textfield-input-lg"
                                          onChange={event =>
                                            this.handleChange(
                                              event,
                                              setFieldValue
                                            )
                                          }
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">
                                          Mobile Number{' '}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="phone_mobile"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'designation') && 
                                    <div className="col-md-12">
                                      <div className="form-group material-textfield">
                                        <Field
                                          type="text"
                                          className="form-control material-textfield-input textfield-input-lg"
                                          name="designation"
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">
                                          Title
                                          {/* <span className="text-danger">
                                                *
                                              </span> */}
                                        </label>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="designation"
                                          component="div"
                                        />
                                      </div>
                                    </div>                                  
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'conferenceContryCode') &&
                                    <div className="col-md-12">
                                      <div className="form-group material-textfield">
                                        <PhoneInput
                                          defaultCountry={'us'}
                                          value={values.conferenceContryCode}
                                          autoFormat={false}
                                          name="conferenceContryCode"
                                          disableAreaCodes={true}
                                          enableLongNumbers={false}
                                          onChange={event =>
                                            setFieldValue(
                                              'conferenceContryCode',
                                              `+${event}`
                                            )
                                          }
                                          buttonClass="flag-textfield"
                                          required
                                        />
                                        <NumberFormat
                                          format="(###) ### #### #####"
                                          value={values.phone_work}
                                          name="phone_work"
                                          id="phone_work"
                                          className="form-control phone-textfield-input material-textfield-input textfield-input-lg"
                                          onChange={event =>
                                            this.handleChange(
                                              event,
                                              setFieldValue
                                            )
                                          }
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">
                                          Conference Phone Number
                                        </label>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="phone_work"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                  } 

                                  {displayFieldForRole(_user.role, values.selectedRole, 'MinMaxPrice') &&
                                    <div className="col-md-12">
                                      <MinMaxPrice
                                        filterParams={this.props.filterParams}
                                        minPrice={values.minPrice}
                                      />
                                    </div>
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'is_schedule_on') && 
                                    <div className="col-md-12">
                                      <div className="status__switch form-group">
                                        <span className="status__switch__text">
                                          Can strategist receive clients?
                                        </span>
                                        <Switch
                                          defaultChecked={
                                            values.is_schedule_on
                                          }
                                          onChange={event =>
                                            this.handleSwitch(
                                              event,
                                              'is_schedule_on',
                                              setFieldValue
                                            )
                                          }
                                          name="is_schedule_on"
                                        />
                                        {values.is_schedule_on ? (
                                          <span className="status yes">
                                            Yes
                                          </span>
                                        ) : (
                                          <span className="status">No</span>
                                        )}
                                      </div>
                                    </div>                                  
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'is_copy_email') &&
                                    <div className="col-md-12">
                                      <div className="status__switch form-group">
                                        <span className="status__switch__text">
                                          Allow user to perform bulk actions on clients?
                                        </span>
                                        <Switch
                                          defaultChecked={
                                            values.is_copy_email
                                          }
                                          onChange={event =>
                                            this.handleSwitch(
                                              event,
                                              'is_copy_email',
                                              setFieldValue
                                            )
                                          }
                                          name="is_copy_email"
                                        />
                                        {values.is_copy_email ? (
                                          <span className="status yes">
                                            Yes
                                          </span>
                                        ) : (
                                          <span className="status">No</span>
                                        )}
                                      </div>
                                    </div>
                                  }
                                      
                                  {displayFieldForRole(_user.role, values.selectedRole, 'calendar_id') && values.is_schedule_on &&
                                    <div className="col-md-12">
                                      <div className="form-group material-textfield">
                                        <NumberFormat
                                          value={values.calendar_id}
                                          name="calendar_id"
                                          id="calendar_id"
                                          className="form-control material-textfield-input textfield-input-lg"
                                          onChange={event =>
                                            this.handleChange(
                                              event,
                                              setFieldValue
                                            )
                                          }
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">
                                          Acuity Calendar ID
                                        </label>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="calendar_id"
                                          component="div"
                                        />
                                      </div>
                                    </div>                                  
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'pay_scale') && 
                                    <div className="col-md-12">
                                      <div className="form-group material-textfield">
                                        <Field
                                          as="select"
                                          className="form-control custom__select material-textfield-input textfield-input-lg"
                                          name="pay_scale"
                                        >
                                          <option value="">Select</option>
                                          <option value="hourly">
                                            Hourly{' '}
                                          </option>
                                          <option value="salary">
                                            Salary
                                          </option>
                                          <option value="flat">Flat</option>
                                        </Field>
                                        <label className="material-textfield-label label-lg">
                                          Pay Scale
                                        </label>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="pay_scale"
                                          component="div"
                                        />
                                      </div>
                                    </div>                                  
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'hourly_amount') &&
                                    <div className="col-md-12">
                                      <div className="form-group material-textfield">
                                        <NumberFormat
                                          value={values.hourly_amount}
                                          name="hourly_amount"
                                          id="hourly_amount"
                                          prefix="$"
                                          thousandSeparator={true}
                                          className="form-control material-textfield-input textfield-input-lg"
                                          // onChange={event =>
                                          //   this.handleChange(
                                          //     event,
                                          //     setFieldValue
                                          //   )
                                          // }
                                          onValueChange={
                                            (value) => {
                                              this.setState({ 'hourly_amount': value.floatValue });
                                              setFieldValue('hourly_amount', value.floatValue);
                                            }
                                          }
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">
                                          Hourly Amount
                                        </label>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="hourly_amount"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'PayoutAdditionalFields') && 
                                    <PayoutAdditionalFields
                                      values={values}
                                      setFieldValue={setFieldValue}
                                    />
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'firm_name') && 
                                    <div className="col-md-12">
                                      <div className="form-group material-textfield">
                                        <Field
                                          type="text"
                                          className="form-control material-textfield-input textfield-input-lg"
                                          name="firm_name"
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">
                                          Firm Name
                                          {/* <span className="text-danger">
                                            *
                                          </span> */}
                                        </label>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="firm_name"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'agent_town') && 
                                    <div className="col-md-12">
                                      <div className="form-group material-textfield">
                                        <Field
                                          type="text"
                                          maxLength="200"
                                          size="200"
                                          className="form-control material-textfield-input textfield-input-lg inputwidth_town"
                                          name="agent_town"
                                          required
                                        />
                                        <label className="material-textfield-label label-lg">
                                          Town agent lives in
                                        </label>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="agent_town"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                  }



                                  {displayFieldForRole(_user.role, values.selectedRole, 'time_zone') && 
                                    <div className="col-md-12">
                                      <div className="form-group material-textfield">
                                        <Field
                                          as="select"
                                          className="form-control custom__select material-textfield-input textfield-input-lg"
                                          name="time_zone"
                                        >
                                          <option value="">
                                            Select Time Zone
                                          </option>
                                          {timeZoneOption}
                                        </Field>
                                        <label className="material-textfield-label label-lg">
                                          Select Time Zone{' '}
                                          {/* <span className="text-danger">*</span> */}
                                        </label>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="time_zone"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'zillow_profile') && 
                                    <div className="col-md-12">
                                      <div className="form-group material-textfield">
                                        <Field
                                          type="text"
                                          maxLength="200"
                                          size="200" 
                                          className="form-control material-textfield-input textfield-input-lg inputwidth_town"
                                          name="zillow_profile"
                                        />
                                        <label className="material-textfield-label label-lg">
                                          Zillow Profile
                                        </label>
                                      </div>
                                    </div>
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'business_profile') && 
                                    <div className="col-md-12">
                                      <div className="form-group material-textfield">
                                        <Field
                                          type="text"
                                          maxLength="200"
                                          size="200" 
                                          className="form-control material-textfield-input textfield-input-lg inputwidth_town"
                                          name="business_profile"
                                        />
                                        <label className="material-textfield-label label-lg">
                                          Business Profile
                                        </label>
                                      </div>
                                    </div>                                  
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'AgentAgreement') && 
                                    <div className="col-md-12">
                                      <div className="form-group material-textfield">
                                        <div className="material-textfield material-textfield-input jc-pdf-changes cursor__pointer" onClick={this.openModal.bind(this, 'onClick')}>
                                          {
                                            showPDF
                                              ?
                                              <div className="jc-pdf-active">
                                                <div className="card__tile__icon grey mt__5" >
                                                  {/* <PdfIcon /> */}
                                                  <img src="../../../../../img/pdf_icon.png" />
                                                </div>
                                                <div className="material-textfield-label ml__35">Agent's Signed Agreement </div>
                                              </div>
                                              :
                                              <div className="material-textfield-label jc_pdf_disable">Agent's Signed Agreement </div>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  }                                  

                                  {displayFieldForRole(_user.role, values.selectedRole, 'description') && 
                                    <div className="col-md-12">
                                      <div className="form-group material-textfield">
                                        <Field
                                          component="textarea"
                                          type="text"
                                          className="form-control material-textfield-input textfield-input-lg"
                                          name="description"
                                          rows="8"
                                          required
                                        ></Field>
                                        <label className="material-textfield-label label-lg">
                                          Enter bio <span class="text-danger">*</span>
                                        </label>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="description"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'is_limit') && 
                                    <div className="col-md-12">
                                      <div className="status__switch form-group">
                                        <span className="status__switch__text">
                                          Limit clients?
                                        </span>
                                        <Switch
                                          defaultChecked={values.is_limit}
                                          name="is_limit"
                                          onChange={event =>
                                            this.handleSwitch(
                                              event,
                                              'is_limit',
                                              setFieldValue
                                            )
                                          }
                                        />
                                        {(values.is_limit) ? (
                                          <span className="status yes">
                                            Yes
                                          </span>
                                        ) : (
                                          <span className="status">No</span>
                                        )}
                                      </div>
                                    </div>
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'isImpersonate') && values.selectedRole === 'strategist' &&
                                    <Fragment>
                                      <div className="col-md-12">
                                        <div className="status__switch form-group">
                                          <span className="status__switch__text">
                                            Allow Strategist to impersonate other Strategist?
                                          </span>
                                          <Switch
                                            defaultChecked={values.isImpersonate}
                                            name="isImpersonate"
                                            onChange={event =>
                                              this.handleSwitch(
                                                event,
                                                'isImpersonate',
                                                setFieldValue
                                              )
                                            }
                                          />
                                          {values.isImpersonate ? (
                                            <span className="status yes">
                                              Yes
                                            </span>
                                          ) : (
                                            <span className="status">No</span>
                                          )}
                                        </div>
                                        <ErrorMessage
                                          className="invalid-feedback"
                                          name="seletedSearchByStrategist"
                                          component="div"
                                        />                                      
                                      </div>
                                      {values.isImpersonate && 
                                        <SelectStrategist
                                          values={values}
                                          seletedSearchByStrategist={values.seletedSearchByStrategist}
                                          searchByStrategist={values.searchByStrategist}
                                          setFieldValue={setFieldValue}
                                        />
                                      }
                                    </Fragment>
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'isImpersonate') && values.selectedRole === 'admin' &&
                                    <div className="col-md-12">
                                      <div className="status__switch form-group">
                                        <span className="status__switch__text">
                                          Allow Admin to impersonate Strategists?
                                        </span>
                                        <Switch
                                          defaultChecked={
                                            values.isImpersonate
                                          }
                                          name="isImpersonate"
                                          onChange={(event) =>
                                            this.handleSwitch(
                                              event,
                                              "isImpersonate",
                                              setFieldValue
                                            )
                                          }
                                        />
                                        {values.isImpersonate ? (
                                          <span className="status yes">
                                            Yes
                                          </span>
                                        ) : (
                                          <span className="status">No</span>
                                        )}
                                      </div>
                                      <ErrorMessage
                                        className="invalid-feedback"
                                        name="seletedSearchByStrategist"
                                        component="div"
                                      />
                                    </div>
                                  }

                                  {displayFieldForRole(_user.role, values.selectedRole, 'SuburbsCheckboxList') && 
                                    <Fragment>

                                      <SuburbsCheckboxList
                                        towns={values.towns}
                                        townDataOption={townDataOption}
                                        selectedRole={values.selectedRole}
                                        setFieldValue={setFieldValue}
                                        priceBracketDataOption={priceBracketDataOption}
                                        getTownsOptionBySuburbs={
                                          this.props.getTownsOptionBySuburbs
                                        }
                                        // strategistAssignmentRules={values.strategistAssignmentRules}
                                        // handleMetroAreaAndStrategistAssignmentRuleChanges={this.handleMetroAreaAndStrategistAssignmentRuleChanges}
                                      />        
                                    </Fragment>
                                  }                          

                                  {displayFieldForRole(_user.role, values.selectedRole, 'SelectTown') && 
                                    <SelectTown
                                      selectedRole={values.selectedRole}
                                      selectedSearchTown={get(
                                        values,
                                        'selectedSearchTown',
                                        []
                                      )}
                                      searchTownOption={values.searchTownOption}
                                      setFieldValue={setFieldValue}
                                    />
                                  }

                                  <button
                                    className="btn btn__btn btn-dark btn__lg w__100"
                                    type="submit"
                                    disabled={isLoading}
                                  >
                                    Save
                                  </button>

                                </div> 
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </main>
        </Spin>
        <ReactModal
          isOpen={this.state.deleteClientModal}
          contentLabel="Delete User"
          portalClassName="react-modal"
        >
          <div className="react-modal-dialog react-modal-dialog-centered">
            <div className="react-modal-body react-modal-body-sm">
              <button
                type="button"
                className="btn react-modal-close react-modal-close-sm"
                onClick={this.toggleDeleteClientModal}
              >
                <CloseIcon />
              </button>
              <h5 className="react-modal-title mb__13">Are you sure?</h5>
              <p className="react-modal-subtitle mb__3">You want to delete this client? This action remove client and its details. Once deleted can't recoverd.</p>
              <div className="text-right">
                <button onClick={this.handleClickDelete} className="btn btn__btn btn-danger w__150">
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
